import React from 'react'
import { Outlet } from 'react-router-dom'
import { SearchProvider } from '../hooks/search-context'
import { GeoJSONDataProvider } from '../contexts/geojson-data'
import { AggSearchProvider } from '../contexts/agg-search'
import DefaultLayout from "../layout/DefaultLayout";
import MinimalLayout from "../layout/MinimalLayout";
import BaseLayout
  from "../layout/BaseLayout";
// import TerritoryBuilder from "../components/Planning/Components/TerritoryBuilder";
// import DataAgg from "../components/Planning/Components/DataAgg";
const Page404 = React.lazy(() => import( '../views/pages/page404/Page404'))
const CheckAuth = React.lazy(() => import('../components/Auth/check-auth'))
const Login = React.lazy(() => import('../views/pages/login/Login'))
const WithAuth = React.lazy(() => import('../components/Auth/with-auth'))
// const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'))
const Treatments = React.lazy(() => import('../components/Glossary/Components/Treatments'))
const Products = React.lazy(() => import('../components/Glossary/Components/Products'))
const Searches = React.lazy(() => import('../components/Manage/Searches'))
const Users = React.lazy(() => import('../components/Manage/Users'))
const UserEdit = React.lazy(() => import('../components/Manage/Users/Edit'))
const UserCreate = React.lazy(() => import('../components/Manage/Users/Create'))
const EditSearch = React.lazy(() => import('../components/Manage/Searches/EditSearch'))
const CreateSearch = React.lazy(() => import('../components/Manage/Searches/CreateSearch'))
const MyLists = React.lazy(() => import('../components/MyLists'))
const ViewList = React.lazy(() => import('../components/MyLists/ViewList'))
const EditList = React.lazy(() => import('../components/MyLists/EditList'))
const CreateList = React.lazy(() => import('../components/MyLists/CreateList'))
const Territories = React.lazy(() => import('../components/Manage/Territories'))
const EditTerritory = React.lazy(() => import('../components/Manage/Territories/EditTerritory'))
const CreateTerritory = React.lazy(() => import('../components/Manage/Territories/CreateTerritory'))
const DataAgg = React.lazy(() => import('../components/Planning/Components/DataAgg'))
const TerritoryBuilder = React.lazy(() => import('../components/Planning/Components/TerritoryBuilder'))
const Planning = React.lazy(() => import('../components/Planning'))
// const MinimalLayout = React.lazy(() => import('../layout/MinimalLayout'))
// const BaseLayout = React.lazy(() => import('../layout/BaseLayout'))
const PlanningHome = React.lazy(() => import("../components/Planning/PlanningHome"))
const Dashboard = React.lazy(() => import('../components/Dashboard'))
const MyAccount = React.lazy(() => import('../views/dashboard/myAccount'))
const Glossary = React.lazy(() => import('../components/Glossary'))
const LocationDetail = React.lazy(() => import ('../components/ClinicDetail'))




const clinic = {
  path: 'clinic',
  name: 'Clinic',
  children: [
    {
      path: ':locationId',
      name: 'View Location',
      element: <LocationDetail/>,
      exact: true,
      handle: {
        crumb: (data) => {
          return <>View Clinic </>
        },
      },
    },
  ],
}

const routes = [
  {
    name: 'Home',
    element: (
      <WithAuth>
        <GeoJSONDataProvider>
          <AggSearchProvider>
        <BaseLayout />
          </AggSearchProvider>
        </GeoJSONDataProvider>
      </WithAuth>
    ),
    children: [

      {
        name: "withDefaultLayout",
        element: <DefaultLayout/>,
        children: [
          {
            path: '/',
            name: 'Dashboard',
            handle: {
              crumb: (data) => {
                return 'Filter Locations'
              }
            },

            children: [
              {
                index: true,

                element: <SearchProvider><Dashboard /></SearchProvider>,
              },
              {
                children: [
                  clinic,
                ],
              }
            ],
          },
          {
            path: 'myaccount',
            name: 'My Account',
            element: <MyAccount/>,
            exact: true,
            handle: {crumb: () => 'My Account'},
          },
          {
            path: '/glossary',
            name: 'Glossary',
            handle: {
              crumb: () => 'Glossary',
            },
            children: [
              {index: true, name: 'Glossary Index', element: <Glossary/>},
              {
                path: 'products',
                name: 'Products',
                element: <Products/>,
                exact: true,
                handle: {
                  crumb: (data) => {
                    return 'Product Catalogue'
                  },
                },
              },
              {
                path: 'treatments',
                name: 'Treaments',
                element: <Treatments/>,
                exact: true,
                handle: {
                  crumb: (data) => {
                    return 'Treatment Catalogue'
                  },
                },
              },
            ],
          },
          {
            path: "lists",
            name: "My Lists",
            handle: {
              crumb: (data) => {
                return "Lists"
              }
            },
            children: [
              {
                index: true,
                element: <MyLists listType={"user"}/>,

              },
              clinic,
              {
                handle: {
                  crumb: (data) => {
                    return "Standard Lists"
                  }
                },
                path: "standard",
                children: [
                  {index: true, element: <MyLists listType={"standard"}/>, },
                  {
                    path: "view/:listId",
                    name: "View List",
                    // element: <ViewList/>,
                    handle: {
                      crumb: (data) => "View List"
                    },
                    children: [
                      {index: true, element: <ViewList />},
                      clinic,
                    ]
                  }
                ],
              },
              {
                path: "view/:listId",
                name: "View List",
                handle: {
                  crumb: (data) => "View List"
                },
                children: [
                  {index: true, element: <ViewList />},
                  clinic,
                ]
              },
              {
                path: "edit/:listId",
                name: "Edit List",
                element: <EditList/>,
                handle: {
                  crumb: (data) => "Edit List"
                }
              },
              {
                path: "create",
                name: "Create List",
                element: <CreateList/>,
                handle: {
                  crumb: (data) => "Create List",
                }
              }
            ]
          },
          {
            path: "manage",
            name: "Management",
            element: <React.Fragment><Outlet/></React.Fragment>,
            exact: true,
            handle: {
              crumb: (data) => {
                return "Management"
              }
            },
            children: [
              {
                index: true,
                element: <><h3>Management Page Information</h3></>
              },
              {
                path: "users",
                exact: true,
                name: "Manage Users",
                handle: {
                  crumb: (data) => {
                    return "Users"
                  }
                },
                children: [
                  {
                    index: true,
                    element: <Users/>,
                  },
                  {
                    name: "Create User",
                    path: "create",
                    element: <UserCreate/>,
                    handle: {
                      crumb: (data) => {
                        return "Create Sub-User"
                      }
                    }
                  },
                  {
                    name: "Edit User",
                    path: "edit/:userId",
                    element: <UserEdit/>,
                    handle: {
                      crumb: (data) => {
                        return "Edit Sub-User"
                      }
                    }
                  },
                ],
              },
              {
                path: "searches",
                exact: true,
                name: "Manage Searches",
                handle: {
                  crumb: (data) => {
                    return "Searches"
                  }
                },
                children: [
                  {
                    index: true,
                    element: <Searches/>,
                  },
                  {
                    name: "Create Search",
                    path: "create",
                    element: <CreateSearch/>,
                    handle: {
                      crumb: (data) => {
                        return "Create Search"
                      }
                    },
                  },
                  {
                    name: "Edit Search",
                    path: "edit/:searchId",
                    element: <EditSearch/>,
                    handle: {
                      crumb: (data) => {
                        return "Edit Search"
                      }
                    },
                  },
                ],
              },
              {
                path: "territories",
                exact: true,
                handle: {
                  crumb: () => "Territories"
                },
                children: [
                  {
                    index: true,
                    element: <Territories/>
                  },
                  {
                    path: "edit/:territoryId",
                    handle: {
                      crumb: () => "Edit Territory"
                    },
                    element: <EditTerritory/>
                  },
                  {
                    path: "create",
                    handle: {
                      crumb: () => "Create New Territory"
                    },
                    element: <CreateTerritory/>
                  }
                ]
              }
            ]
          },
          {
            path: "planning",
            element: <PlanningHome />,
            handle: {
              crumb: () => "Planning and Analysis"
            },
          }
        ]
      },

      {
        name: "withMinimalLayout",
        element: <MinimalLayout />,
        children: [
          {
            path: "planning",
            // name: "",
            // handle: {
            //   crumb: () => "Sales Targeting"
            // },
            element: <Planning />,
            // element: <SalesTargeting />,
            children: [

              {
                handle: {
                  crumb: () => "Territory Builder"
                },
                path: "territories",
                element: <TerritoryBuilder />,
              },
              {
                path: "regional-analysis",
                handle: {
                  crumb: () => "Analytics"
                },
                element: <DataAgg />
              },
            ]
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    name: 'Login Page',
    element: (
      <CheckAuth>
        <Login />
      </CheckAuth>
    ),
    exact: true,
  },
  { path: '*', name: 'Page 404', element: <Page404 /> },
]


export default routes
